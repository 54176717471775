import { ChangeEventHandler, FC } from "react";
import { PropCondition } from "@/interfaces";

interface ConditionFormInputFieldProps {
    fieldChange: ChangeEventHandler<HTMLInputElement>;
    propertyCondition: PropCondition;
    fieldName: string;
    label: string;
    type?: 'text' | 'number';
    size?: number;
}

const ConditionFormInputField: FC<ConditionFormInputFieldProps> = ({
    fieldChange,
    propertyCondition,
    fieldName,
    label,
    type = 'text',
    size = 20,
}: ConditionFormInputFieldProps) => {
    return (<div className="field">
        <label className="label" htmlFor={fieldName}>{label}</label>
        <input
            type={type}
            className="input"
            defaultValue={(propertyCondition as any)[fieldName]}
            name={fieldName}
            onChange={fieldChange}
            size={size}
        />
    </div>);
};

export { ConditionFormInputField };
