import { FC, useState } from 'react';
import { Date } from './Date';
import { IconTrash, IconSearch, IconClose,IconEye } from './Icons';
import { Link } from 'react-router-dom';

interface EventTableProps {
    events: any[];
    onDelete: (eventId: number) => void;
}

export const EventTable: FC<EventTableProps> = ({events, onDelete}) => {
    const [selectedEvent, setSelectedEvent] = useState<number>(-1);

    return (
        <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
                <tr>
                    <th>Datetime</th>
                    <th>Event type</th>
                    <th>Profile ID</th>
                    <th>String</th>
                    <th>Int</th>
                    <th>Topics</th>
                    <th className="has-text-centered">Actions</th>
                </tr>
            </thead>
                {events.map((event: any, idx: number) => {
                    const isSelected = selectedEvent === idx;
                    return (
                        <tbody key={idx}>
                        
                        <tr>
                            <td width="115"><Date date={event.ctime} /></td>
                            <td>{event.evt}</td>
                            <td>{event.cid}</td>
                            <td>{event.strval}</td>
                            <td>{event.intval}</td>
                            <td>{event.topics?.join(', ')}</td>
                            <td>
                                <div className='buttons'>
                                 <div className="field has-addons">
                                    <p className="control">
                                      <Link to={`/admin/event/id-of-the-event`}><span className="button"><IconEye /></span></Link>
                                    </p>
                                            <p className="control">
                                    <button
                                            className='button'
                                            onClick={(target) => {
                                                const targetIndex = isSelected ? -1 : idx;
                                                setSelectedEvent(targetIndex);
                                            }}
                                    >{!isSelected ? <IconSearch /> : <IconClose />}</button></p>
                                   <p className="control"> <button
                                        className='button has-text-danger'
                                        onClick={(target) => {
                                            onDelete(event.id);
                                        }}
                                        ><IconTrash /></button></p>
                                        </div>
                                </div>
                            </td>
                        </tr>
                        {selectedEvent === idx && (
                            <tr>
                                <td colSpan={7}>
                                    <pre>{JSON.stringify(event, null, 2)}</pre>
                                </td>
                            </tr>
                        )}
                  
                        </tbody>
                    );
                })}
        </table>
    )};