import { useEffect, useState, useRef, FC } from 'react';
import CdpClient from '@/client';
import { Button } from '@/components/Button';
import { IconTrash, IconSearch, IconClose } from '@/components/Icons';
import { EventTable } from '@/components/EventTable';

interface EventTestPageProps {
    cdpClient: CdpClient;
}



const EventLogPage: FC<EventTestPageProps> = ({ cdpClient }) => {
    const [events, setEvents] = useState<any>([]);
    const [selectedEvent, setSelectedEvent] = useState<number>(-1);
    const [filename, setFilename] = useState<string>('');
    const [fileReady, setFileReady] = useState<boolean>(false);
    const [fileCheckInterval, setFileCheckInterval] = useState<any>(null);
    const [eventSpecs, setEventSpecs] = useState<any>([]);
    const [cid, setCid] = useState<string>('');
    const [evtType, setEvt] = useState<string>('');
    const once = useRef(false);
    useEffect(() => {
        if (!once.current) {
            cdpClient.getEvents((response: any) => {
                setEvents(response || []);
            })
            cdpClient.getEventSpecs((response: any) => {
                setEventSpecs(Object.values(response));
            });
            once.current = true;
        }
    });
    return (
    <div className='container'>
        <div className="level toolbar">
            <div className="level-left">
                <div className="level-item">
                <h2 className="title">Event log</h2>
                </div>
            </div>
        </div>
        
        <div className='box'>
            <div className='level'>
                <div className='level-left'>
                    <div className='level-item'>
                        <div className='field'>
                            <input className='input' type='text' placeholder='Customer ID' onChange={(evt) => setCid(evt.target.value)} />
                        </div>
                    </div>
                    <div className='level-item'>
                        <div className='select'>
                            <select onChange={(evt) => setEvt(evt.target.value)}>
                                <option value="">Event type</option>
                                {eventSpecs.map((spec: any, idx: number) => {
                                    return (
                                        <option key={idx} value={spec.evt}>{spec.evt}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='buttons'>
                        <Button
                            className='is-primary is-medium'
                            onClick={() => {
                                if (cid === '') {
                                    cdpClient.getEvents((response: any) => {
                                        setEvents(response || []);
                                    }, evtType);
                                    return;
                                }
                                cdpClient.getEventsForCustomer(cid, evtType, (response: any) => {
                                    setEvents(response || []);
                                })
                            }}
                            
                            ><IconSearch /></Button>
                            <Button
                                className='is-info is-medium'
                                onClick={() => {
                                    cdpClient.createEventCsv((response: any) => {
                                        setFilename(response.filename);
                                        setFileCheckInterval(setInterval(() => {
                                            cdpClient.checkEventCsv(response.filename, (response: any) => {
                                                setFileReady(true);
                                            });
                                        }, 5000));
                                    })
                                }}
                                >Create CSV</Button>
                            {filename !== '' && (
                                <Button
                                    disabled={!fileReady}
                                    className='is-success is-medium'
                                    onClick={() => {
                                        window.open(`${cdpClient.host}/v1/export/events/send?filename=${filename}`)
                                        setFilename('');
                                        clearInterval(fileCheckInterval);
                                        setFileCheckInterval(null);
                                    }}
                                    >Download</Button>
                            )
                            }
                    </div>
                </div>
            </div>
            <EventTable
                events={events}
                onDelete={(id: number) => {
                    cdpClient.deleteEvent(id, () => {
                        cdpClient.getEvents((response: any) => {
                            setEvents(response || []);
                        });
                    });
                }}
            />
        </div>
    </div>
    )
}
export { EventLogPage }
